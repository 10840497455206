export function round(num, places) {
  return +(Math.round(num + "e+" + places) + "e-" + places);
}

export function printerJobStatusColor(status, tailwind = false) {
  if (status === "Setup") {
    return tailwind ? "#bg-[#48e28d]" : "#48e28d";
  } else if (status === "Running") {
    return tailwind ? "#bg-[#1cad2a]" : "#1cad2a";
  } else if (status === "Idle") {
    return tailwind ? "#bg-[#ffcd56]" : "#ffcd56";
  } else if (status === "Stopped") {
    return tailwind ? "#bg-[#ff6384]" : "#ff6384";
  }
}

export function toDuration(sec) {
  if (sec === 0) {
    return 0;
  }

  moment.relativeTimeThreshold("ss", 0);
  moment.updateLocale("nl", {
    relativeTime: {
      ss: "%ds",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      M: "1mo",
      MM: "%dmo",
      y: "1y",
      yy: "%dy",
    },
  });

  const time = moment().subtract(sec, "seconds");

  return time.locale("nl").fromNow(true);
}

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}
