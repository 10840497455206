<template>
  <svg v-bind="$attrs">
    <use :xlink:href="`${url}#${id}`"></use>
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "custom-icons",
    },
  },
  computed: {
    url() {
      // No icon support for SSR mode (will code if needed)

      return typeof window === "undefined" ? null : window.MIX[`/icons/${this.type}.svg`];
    },
  },
};
</script>
